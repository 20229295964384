import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import AccessoryChairs from '../components/AccessoryChairs'
import Furniture from '../components/Furniture'
import ProductView from '../components/ProductView'

export const pageQuery = graphql`
  {
    NailTableTower1: file(
      relativePath: { eq: "furniture/nail-tables/tower/nail_table_tower_close_draw.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          width: 500
          height: 500
          quality: 100
          layout: CONSTRAINED
        )
      }
    }
    NailTableTower2: file(
      relativePath: { eq: "furniture/nail-tables/tower/nail_table_tower_open_draw.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          width: 500
          height: 500
          quality: 100
          layout: CONSTRAINED
        )
      }
    }
    NailTable35InchesOne: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesTwo: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesThree: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesFour: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesFive: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesSix: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesOne: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesTwo: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesThree: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesFour: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesFive: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesSix: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const AccessoriesPage = props => {
  return (
    <Layout>
      <SEO
        title="Massage Chair Accessories"
        description="Made with durable and acetone resistant material, Contego Spa Designs Accessories, Mani Stool, Pedi Stool, Tech Chair, and Customer Chair will satisfy you. You will not worry about spilling any more."
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <AccessoryChairs
        bg="manistool-bg"
        model="manistool"
        name="Mani Stool"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="burgundy"
        defaultTexture="regular"
        price="175"
      />

      <AccessoryChairs
        bg="pedistool-bg"
        model="pedistool"
        name="Pedi Stool"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="black"
        defaultTexture="diamond"
        price="225"
      />

      <AccessoryChairs
        bg="techchair-bg"
        model="techchair"
        name="Tech Chair"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="cappuccino"
        defaultTexture="regular"
        price="235"
      />

      <AccessoryChairs
        bg="customerchair-bg"
        model="customerchairsofa"
        name="Customer Chair - Sofa Style"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="black"
        defaultTexture="regular"
        price="495"
      />

      <AccessoryChairs
        bg="customerchair-bg"
        model="customerchair"
        name="Customer Chair"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="ivory"
        defaultTexture="regular"
        price="375"
      />

      <Furniture name="DIP POWDER CABINET" model="DipPowderCabinet" price="995" />
      <Furniture name="GEL POLISH CABINET" model="GelPolishCabinet" price="995" />

      <div className="row d-flex align-items-center justify-content-between">
        <div className="col-lg-6">
          <ProductView
            price="945"
            name='NAIL TABLE 35"'
            items={[
              {
                title: 'Nail Table 35" inches close draws',
                type: 'img',
                url: props.data.NailTable35InchesOne.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" side view',
                type: 'img',
                url: props.data.NailTable35InchesTwo.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened left draw',
                type: 'img',
                url: props.data.NailTable35InchesThree.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened center draw',
                type: 'img',
                url: props.data.NailTable35InchesFour.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened right draw',
                type: 'img',
                url: props.data.NailTable35InchesFive.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened draws',
                type: 'img',
                url: props.data.NailTable35InchesSix.childImageSharp.gatsbyImageData
              }
            ]}
          />
        </div>
        <div className="col-lg-6">
          <ProductView
            price="995"
            name='NAIL TABLE 38"'
            items={[
              {
                title: 'Nail Table 38" inches close draws',
                type: 'img',
                url: props.data.NailTable38InchesOne.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" side view',
                type: 'img',
                url: props.data.NailTable38InchesTwo.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened left draw',
                type: 'img',
                url: props.data.NailTable38InchesThree.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened center draw',
                type: 'img',
                url: props.data.NailTable38InchesFour.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened right draw',
                type: 'img',
                url: props.data.NailTable38InchesFive.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened draws',
                type: 'img',
                url: props.data.NailTable38InchesSix.childImageSharp.gatsbyImageData
              }
            ]}
          />
        </div>
      </div>

      <div className="row shadow px-2 rounded">
        <div className="col">
          <ProductView
            price="495"
            name="LUNA NAIL TABLE TOWER"
            items={[
              {
                title: 'Nail Table Tower Close Draw',
                type: 'img',
                url: props.data.NailTableTower1.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table Tower Open Draw',
                type: 'img',
                url: props.data.NailTableTower2.childImageSharp.gatsbyImageData
              }
            ]}
          />
        </div>
      </div>
    </Layout>
  )
}

export default AccessoriesPage
